<script context="module">
  import FileConverter from "./FileConverter.svelte";

  // compare contente of two buffers with same size
  // return 0 if same content, return -1 if different
  const bufferCompare = (b1, b2) => {
    if (b1.byteLength !== b2.byteLength) {
      throw new Error("Needle and haystack is different size");
    }

    for (let i = 0; i < b1.byteLength; i += 1) {
      if (b1[i] !== b2[i]) return -1;
    }

    // buffers are identical
    return 0;
  };

  // look for matching needle buffer in haystack buffer and return position of start of match
  // returns -1 if no match found
  const byteMatch = (haystack, needle) => {
    if (needle.byteLength > haystack.byteLength) {
      throw new Error("Needle size is larger than haystack size");
    }
    for (let i = 0; i < haystack.byteLength - needle.length; i += 1) {
      if (
        bufferCompare(haystack.slice(i, i + needle.byteLength), needle) === 0
      ) {
        // console.log("Match at position:", i);
        return i;
      }
    }

    // no match found
    return -1;
  };

  // search and replace buffer contents, this will modify the buffer
  const bufferReplace = (haystack, needleBefore, needleAfter) => {
    if (needleBefore.byteLength !== needleAfter.byteLength) {
      throw new Error("Before and after replace contents are different sizes");
    }
    const matchPos = byteMatch(haystack, needleBefore); // returns position of match, -1 if no match found

    if (matchPos >= 0) {
      // match found, replace buffer contents at position match
      for (let i = matchPos; i < matchPos + needleBefore.byteLength; i += 1) {
        haystack[i] = needleAfter[i];
      }
      return matchPos;
    }

    // no match found
    return -1;
  };

  // JS part of PDF object which represents "this.print({bUI: false, bSilent: true, bShrinkToFit: true});"
  const needle = new Uint8Array([
    0x30, 0x20, 0x6f, 0x62, 0x6a, 0x0a, 0x3c, 0x3c, 0x2f, 0x4c, 0x65, 0x6e,
    0x67, 0x74, 0x68, 0x20, 0x35, 0x38, 0x2f, 0x46, 0x69, 0x6c, 0x74, 0x65,
    0x72, 0x2f, 0x46, 0x6c, 0x61, 0x74, 0x65, 0x44, 0x65, 0x63, 0x6f, 0x64,
    0x65, 0x3e, 0x3e, 0x0a, 0x73, 0x74, 0x72, 0x65, 0x61, 0x6d, 0x0a, 0x78,
    0x9c, 0x2b, 0xc9, 0xc8, 0x2c, 0xd6, 0x2b, 0x28, 0xca, 0xcc, 0x2b, 0xd1,
    0xa8, 0x4e, 0x0a, 0xf5, 0xb4, 0x52, 0x48, 0x4b, 0xcc, 0x29, 0x4e, 0xd5,
    0x51, 0x48, 0x0a, 0xce, 0xcc, 0x49, 0xcd, 0x2b, 0xb1, 0x52, 0x28, 0x29,
    0x2a, 0x05, 0x73, 0x33, 0x80, 0x6a, 0xb2, 0x43, 0xf2, 0xdd, 0x32, 0xa1,
    0x62, 0xb5, 0x9a, 0xd6, 0x00, 0x90, 0xfa, 0x15, 0x51, 0x0a, 0x65, 0x6e,
    0x64, 0x73, 0x74, 0x72, 0x65, 0x61, 0x6d, 0x0a, 0x65, 0x6e, 0x64, 0x6f,
    0x62, 0x6a, 0x0a,
  ]);

  const needleNew = new Uint8Array([
    0x30, 0x20, 0x6f, 0x62, 0x6a, 0x0a, 0x3c, 0x3c, 0x2f, 0x4c, 0x65, 0x6e,
    0x67, 0x74, 0x68, 0x20, 0x35, 0x38, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20,
    0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20,
    0x20, 0x3e, 0x3e, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20,
    0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20,
    0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20,
    0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20,
    0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20,
    0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x0a, 0x65, 0x6e,
    0x64, 0x73, 0x74, 0x72, 0x65, 0x61, 0x6d, 0x0a, 0x65, 0x6e, 0x64, 0x6f,
    0x62, 0x6a, 0x0a,
  ]);
</script>

<script>
  let errors = [];

  // process each file, perform conversion operations as needed
  // data is a uint8array
  const process = (data, { file }) => {
    // warning, bufferReplace() mutates the buffer, returns -1 if no change
    if (bufferReplace(data, needle, needleNew) === -1) {
      errors.push(`${file.name}: No suspect code found, no changes made.`);
      errors = errors;
      return { data, modified: false };
    }
    return { data, modified: true };
  };

  const handleStart = () => {
    // clear errors at start of drop event
    errors = [];
  };

  const handleComplete = () => {
    // drop completed - this is not the end of processing
  };
</script>

<FileConverter {process} on:start={handleStart} on:complete={handleComplete}>
  <h1>Remove JS print command from Reckon Hosted generated PDF</h1>
  <strong>Drag and drop one or multiple</strong> PDF files into this window to
  remove annoying JavaScript "print now" commands.
  <p>
    PDF files generated by Reckon Hosted contain JavaScript code which attempts
    to automatically force the PDF client to print. This attempts to remove this
    behaviour by removing the offending JavaScript code.
  </p>
  <p>
    All modifications are performed inside the browser and no data is stored or
    transmitted elsewhere.
  </p>
  <p>
    Source code: <a href="https://github.com/andygock/pdf-clean-up">GitHub</a>
  </p>
</FileConverter>

<!-- {#if errors.length}
  <div class="errors">
    <h2>Errors and warnings</h2>
    {#each errors as error}
      <p>{error + "\n"}</p>
    {/each}
  </div>
{/if} -->
<style>
  .errors {
    font-size: small;
    color: red;
    position: fixed;
    bottom: 0;
    z-index: 10;
  }

  .errors p {
    font-family: "Courier New", Courier, monospace;
    margin: 0 0 0 0;
  }
</style>
